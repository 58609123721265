import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _MaterialTableLocalization } from 'links/TableConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));

export default function CondEducacionais({ familia_id, filter }) {
    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");
    // CondEducacionais
    const [id, setId] = useState("");
    const [st_leitura_escrita, setSt_leitura_escrita] = useState("");
    const [st_frequenta_escola, setSt_frequenta_escola] = useState("");
    const [cd_escolaridade, setCd_escolaridade] = useState("");
    const [nm_escolaridade, setNm_escolaridade] = useState("");
    const [dt_anotacao, setDt_anotacao] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [nm_escolaridade_outro, setNm_escolaridade_outro] = useState("");
    const [outroEscolaridadeOff, setOutroEscolaridadeOff] = useState(true);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);
    /* Carregar Pessoa e CondEducacionais*/
    const [rows, setRows] = useState([]);
    const classes = useStyles();

    /* Set Escolaridade */
    const setEscolaridade = (codigo) => {

        setCd_escolaridade(codigo);

        if (codigo === '00') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('Nunca frequentou escola');
        }
        else if (codigo === '01') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('Creche');
        }
        else if (codigo === '02') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('Educação Infantil');
        }
        else if (codigo === '11') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('1º ano E. Fundamental');
        }
        else if (codigo === '12') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('2º ano E. Fundamental');
        }
        else if (codigo === '13') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('3º ano E. Fundamental');
        }
        else if (codigo === '14') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('4º ano E. Fundamental');
        }
        else if (codigo === '15') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('5º ano E. Fundamental');
        }
        else if (codigo === '16') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('6º ano E. Fundamental');
        }
        else if (codigo === '17') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('7º ano E. Fundamental');
        }
        else if (codigo === '18') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('8º ano E. Fundamental');
        }
        else if (codigo === '19') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('9º ano E. Fundamental');
        }
        else if (codigo === '21') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('1º ano E. Médio');
        }
        else if (codigo === '22') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('2º ano E. Médio');
        }
        else if (codigo === '23') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('3º ano E. Médio');
        }
        else if (codigo === '30') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('Superior Incompleto');
        }
        else if (codigo === '31') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('Superior Completo');
        }
        else if (codigo === '40') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('EJA - Ensino Fundamental');
        }
        else if (codigo === '41') {
            setNm_escolaridade_outro(null);
            setOutroEscolaridadeOff(true);
            setNm_escolaridade('EJA - Ensino Médio');
        }
        else if (codigo === '99') {
            setOutroEscolaridadeOff(false);
        }

    }

    const setEscolaridadOutro = (texto) => {

        setNm_escolaridade_outro(texto);
        setNm_escolaridade(texto);
    }

    /* Cadastrar CondEducacionais*/
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (cd_escolaridade === "")
            swal("Escolaridade é obrigatório!", "", "error")

        else if (dt_anotacao === "")
            swal("Data da anotação é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                st_leitura_escrita,
                st_frequenta_escola,
                cd_escolaridade,
                nm_escolaridade,
                dt_anotacao,
                ds_observacao
            };

            setSending(true);
            LaConFetch(`/api/cadastrarCondEducacionais`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    const load = () => {
        setLoading(true);
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultCondEducacionais/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.success) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    /* SetDadosCondEducacionais */
    const setDadosCondEducacionais = (dados) => {

        /* Dados */
        setId(dados.id);
        setSt_leitura_escrita(dados.st_leitura_escrita);
        setSt_frequenta_escola(dados.st_frequenta_escola)
        setEscolaridade(dados.cd_escolaridade);
        if (dados.cd_escolaridade === '99') {
            setEscolaridadOutro(dados.nm_escolaridade)
        }
        setDt_anotacao(dados.dt_anotacao);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setSt_leitura_escrita("");
        setSt_frequenta_escola("")
        setCd_escolaridade("");
        setNm_escolaridade("");
        setDs_observacao("");
        setNm_escolaridade_outro("");
        setDt_anotacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [openEdit, setOpenEdit] = React.useState(false);

    const handleOpen = () => {
        clear();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const modalEdit = (dados) => {
        setDadosCondEducacionais(dados);
        setOpenEdit(true);
    };

    /* Editar CondEducacionais*/
    const editar = () => {

        const formDados = {
            st_leitura_escrita,
            st_frequenta_escola,
            cd_escolaridade,
            nm_escolaridade,
            dt_anotacao,
            ds_observacao
        };

        setSending(true);
        LaConFetch(`/api/editarCondEducacionais/${id}`, result => {
            load();
            setSending(false);
            swal("Editado com sucesso!", "", "success")
            setOpen(false)
        }, () => { }, formDados)

    };

    /* Remover CondEducacionais */
    const remover = (dados) => {

        const id = dados.id;

        setSending(true);
        LaConFetch(`/api/removerCondEducacionais/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>Educação</Typography>
            <Button onClick={handleOpen} variant="contained" color="primary">
                Adicionar Educação
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Escolaridade', field: 'cd_escolaridade', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Escolaridade', field: 'nm_escolaridade', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data da Anotação', field: 'dt_anotacaoTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            options={{
                actionsColumnIndex: -1
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_MaterialTableLocalization}
            icons={_materialTableIcons}
            title="Condições Educacionais"
        />

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <div className={classes.paper}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Condições Educacionais</InputLabel>

                <Grid container>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 15 }}
                            id="pessoa_id"
                            value={pessoa_id}
                            label="Nome da pessoa*"
                            onChange={(e) => setPessoa_id(e.target.value)}
                            select>
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <FormControl style={{ marginTop: 15, marginRight: '2%' }} fullWidth component="fieldset">
                            <FormLabel component="legend">Frequenta escola?</FormLabel>
                            <RadioGroup row value={st_frequenta_escola} aria-label="st_frequenta_escola" name="st_frequenta_escola" onChange={(e) => setSt_frequenta_escola(e.target.value)}>
                                <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                                <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <TextField
                            variant="outlined"
                            style={{ marginTop: 15, marginRight: '2%' }}
                            fullWidth
                            id="cd_escolaridade"
                            value={cd_escolaridade}
                            label="Código da Escolaridade*"
                            onChange={(e) => setEscolaridade(e.target.value)}
                            select>
                            <MenuItem value="00">00 - Nunca frequentou escola</MenuItem>
                            <MenuItem value="01">01 - Creche</MenuItem>
                            <MenuItem value="02">02 - Educação Infantil</MenuItem>
                            <MenuItem value="11">11 - 1º ano E. Fundamental</MenuItem>
                            <MenuItem value="12">12 - 2º ano E. Fundamental</MenuItem>
                            <MenuItem value="13">13 - 3º ano E. Fundamental</MenuItem>
                            <MenuItem value="14">14 - 4º ano E. Fundamental</MenuItem>
                            <MenuItem value="15">15 - 5º ano E. Fundamental</MenuItem>
                            <MenuItem value="16">16 - 6º ano E. Fundamental</MenuItem>
                            <MenuItem value="17">17 - 7º ano E. Fundamental</MenuItem>
                            <MenuItem value="18">18 - 8º ano E. Fundamental</MenuItem>
                            <MenuItem value="19">19 - 9º ano E. Fundamental</MenuItem>
                            <MenuItem value="21">21 - 1º ano E. Médio</MenuItem>
                            <MenuItem value="22">22 - 2º ano E. Médio</MenuItem>
                            <MenuItem value="23">23 - 3º ano E. Médio</MenuItem>
                            <MenuItem value="30">30 - Superior Incompleto</MenuItem>
                            <MenuItem value="31">31 - Superior Completo</MenuItem>
                            <MenuItem value="40">40 - EJA - Ensino Fundamental</MenuItem>
                            <MenuItem value="41">41 - EJA - Ensino Médio</MenuItem>
                            <MenuItem value="99">99 - Outro</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        {/* {cd_escolaridade == '99'? */}
                        <TextField
                            disabled={outroEscolaridadeOff}
                            margin="normal"
                            fullWidth
                            value={nm_escolaridade_outro}
                            id="nm_escolaridade_outro"
                            label="Caso outro, qual?"
                            variant="outlined"
                            onChange={(e) => setEscolaridadOutro(e.target.value)}
                        />
                        {/* :null} */}
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <TextField
                            margin="normal"
                            style={{ marginRight: '2%' }}
                            fullWidth
                            id="st_leitura_escrita"
                            label="Qual a situação de leitura e escrita?"
                            variant="outlined"
                            value={st_leitura_escrita}
                            onChange={(e) => setSt_leitura_escrita(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="dt_anotacao"
                            label="Data da Anotação*"
                            type="date"
                            value={dt_anotacao}
                            onChange={(e) => setDt_anotacao(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>

                <br></br><br></br>

                <Button onClick={handleClose} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={enviar} style={{ float: 'right' }} variant="contained" color="primary">
                    Enviar
                </Button>

                <br></br><br></br>

            </div>

        </Modal>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openEdit}
            onClose={handleCloseEdit}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <div className={classes.paper}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Condições Educacionais</InputLabel>

                <TextField
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                    style={{ marginTop: 15, width: '100%' }}
                    id="nm_pessoa"
                    value={nm_pessoa}
                    label="Nome da pessoa*"
                />

                <FormControl style={{ marginTop: 15, marginRight: '2%', width: '15%' }} component="fieldset">
                    <FormLabel component="legend">Frequenta escola?</FormLabel>
                    <RadioGroup row value={st_frequenta_escola} aria-label="st_frequenta_escola" name="st_frequenta_escola" onChange={(e) => setSt_frequenta_escola(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    variant="outlined"
                    style={{ marginTop: 15, marginRight: '2%', width: '24%' }}
                    id="cd_escolaridade"
                    value={cd_escolaridade}
                    label="Código da Escolaridade*"
                    onChange={(e) => setEscolaridade(e.target.value)}
                    select>
                    <MenuItem value="00">00 - Nunca frequentou escola</MenuItem>
                    <MenuItem value="01">01 - Creche</MenuItem>
                    <MenuItem value="02">02 - Educação Infantil</MenuItem>
                    <MenuItem value="11">11 - 1º ano E. Fundamental</MenuItem>
                    <MenuItem value="12">12 - 2º ano E. Fundamental</MenuItem>
                    <MenuItem value="13">13 - 3º ano E. Fundamental</MenuItem>
                    <MenuItem value="14">14 - 4º ano E. Fundamental</MenuItem>
                    <MenuItem value="15">15 - 5º ano E. Fundamental</MenuItem>
                    <MenuItem value="16">16 - 6º ano E. Fundamental</MenuItem>
                    <MenuItem value="17">17 - 7º ano E. Fundamental</MenuItem>
                    <MenuItem value="18">18 - 8º ano E. Fundamental</MenuItem>
                    <MenuItem value="19">19 - 9º ano E. Fundamental</MenuItem>
                    <MenuItem value="21">21 - 1º ano E. Médio</MenuItem>
                    <MenuItem value="22">22 - 2º ano E. Médio</MenuItem>
                    <MenuItem value="23">23 - 3º ano E. Médio</MenuItem>
                    <MenuItem value="30">30 - Superior Incompleto</MenuItem>
                    <MenuItem value="31">31 - Superior Completo</MenuItem>
                    <MenuItem value="40">40 - EJA - Ensino Fundamental</MenuItem>
                    <MenuItem value="41">41 - EJA - Ensino Médio</MenuItem>
                    <MenuItem value="99">99 - Outro</MenuItem>
                </TextField>

                {/* {cd_escolaridade == '99'? */}
                <TextField
                    disabled={outroEscolaridadeOff}
                    margin="normal"
                    style={{ width: '57%' }}
                    value={nm_escolaridade_outro}
                    id="nm_escolaridade_outro"
                    label="Caso outro, qual?"
                    variant="outlined"
                    onChange={(e) => setEscolaridadOutro(e.target.value)}
                />
                {/* :null} */}

                <TextField
                    margin="normal"
                    style={{ width: '82%', marginRight: '2%' }}
                    id="st_leitura_escrita"
                    label="Qual a situação de leitura e escrita?"
                    variant="outlined"
                    value={st_leitura_escrita}
                    onChange={(e) => setSt_leitura_escrita(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ width: '16%' }}
                    id="dt_anotacao"
                    label="Data da Anotação*"
                    type="date"
                    value={dt_anotacao}
                    onChange={(e) => setDt_anotacao(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="normal"
                    style={{ width: '100%' }}
                    id="ds_observacao"
                    label="Observação"
                    variant="outlined"
                    value={ds_observacao}
                    onChange={(e) => setDs_observacao(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <br></br><br></br>

                <Button onClick={handleCloseEdit} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={editar} style={{ float: 'right' }} variant="contained" color="primary">
                    Editar
                </Button>

                <br></br><br></br>

            </div>

        </Modal>
    </div>

}