import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PersonIcon from '@material-ui/icons/Person';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import swal from 'sweetalert';
import CPFMask from './Outros/CPFMask';
import CEPMask from './Outros/CEPMask';
import { link } from '../links/Links';
import cep from 'cep-promise'
import { useHistory } from 'react-router';
import { LaConFetch } from 'links/LaConFetch';
import { isCPF, isCEP } from 'helpers/Validators';
import { tipoAcessoAtendimentos } from 'helpers/Fields';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index, current) {
    return {
        nomeAtivo: index === current,
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    Tabs: {
        backgroundColor: 'green',
        color: 'white',
    },
}));

/* TAG CRIADA */
function TabFixed({ nome = "", nomeAtivo = false, ...props }) {
    return <Tooltip title={<p style={{ fontSize: 12, margin: 5 }}>{nome}</p>}>
        <Tab label={nome} style={{ minWidth: nomeAtivo ? 150 : 0 }} {...props} />
    </Tooltip>
};

export default function Cadastro() {

    const classes = useStyles();

    /* Tabs */
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /* Backdrop */
    const [sending, setSending] = useState(false);

    /* Tab Changes */
    const [familia, setFamilia] = useState(true);
    const [responsavel, setResponsavel] = useState(true);

    /* --------------------------  Atendimento ----------------------------- */

    /* Detalhes do Atendimento */
    const [cd_acesso_atendimento, setCd_acesso_atendimento] = useState("");
    const [ds_acesso_atendimento, setDs_acesso_atendimento] = useState("");
    const [ds_motivacao_atendimento, setDs_motivacao_atendimento] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");

    /* Registro do Atendimento */
    const [dt_atendimento, setDt_atendimento] = useState("");
    const [ds_atendimento, setDs_atendimento] = useState("");
    const [cd_atendimento, setCd_atendimento] = useState("");
    const [nm_tecnico, setNm_tecnico] = useState(localStorage.getItem('name'));

    /* Valor mostrado em outro */
    const [ds_acesso_atendimento_outro, setDs_acesso_atendimento_outro] = useState("");
    const [ds_atendimento_outro, setDs_atendimento_outro] = useState("");

    /* Desabilitar Outro */
    const [outroAcessoAtendimentoOff, setOutroAcessoAtendimentoOff] = useState(true);
    const [outroAtendimentoOff, setOutroAtendimentoOff] = useState(true);

    const history = useHistory()

    /* Set CD_ACESSO_ATENDIMENTO e DS_ACESSO_ATENDIMENTO */
    const setAcessoAtendimento = (codigo) => {
        let selected = {};
        tipoAcessoAtendimentos.forEach(item => {
            if (item.id === codigo) {
                selected = item;
            }
        })
        setCd_acesso_atendimento(codigo);
        setOutroAcessoAtendimentoOff(!selected.other);
        setDs_acesso_atendimento(selected.title);
        setDs_acesso_atendimento_outro("");
    }

    const setOutroAcessoAtendimento = (texto) => {

        setDs_acesso_atendimento_outro(texto);
        setDs_acesso_atendimento(texto);
    }

    /* Set CD_ATENDIMENTO e DS_ATENDIMENTO */
    const setAtendimento = (codigo) => {

        setCd_atendimento(codigo);

        if (codigo === '1') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Atendimento socioassistencial individualizado');
        }
        else if (codigo === '2') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Atendimento em atividade coletiva de caráter continuado');
        }
        else if (codigo === '3') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Participação em atividade coletiva de caráter não continuado');
        }
        else if (codigo === '4') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Cadastramento / Atualização Cadastral');
        }
        else if (codigo === '5') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Acompanhamento de MSE');
        }
        else if (codigo === '6') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Solicitação / Concessão de Benefício Eventual');
        }
        else if (codigo === '7') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Visita Domiciliar');
        }

        else if (codigo === '8') {
            setOutroAtendimentoOff(false);
        }

    }

    const setOutroAtendimento = (texto) => {
        setDs_atendimento_outro(texto);
        setDs_atendimento(texto);
    }

    const enviarAtendimento = () => {

        if (dt_atendimento === "")
            swal("Data de atendimento é obrigatório!", "", "error")
        else if (nm_tecnico === "")
            swal("Nome do técnico é obrigatório!", "", "error")
        else {
            setFamilia(false);
            setValue(1);
        }
    };

    const voltarFamilia = () => {
        setValue(0);
    };

    const voltarResponsavel = () => {
        setValue(1);
    };

    /* ------------------------  Familia ------------------------ */

    /* Especifidades Sociais */
    const [cd_especifidades_sociais, setCd_especifidades_sociais] = useState("");
    const [ds_especifidades_sociais, setDs_especifidades_sociais] = useState("");
    const [ds_povo, setDs_povo] = useState("");

    /* Valor mostrado em outro */
    const [ds_especifidades_outro, setDs_especifidades_outro] = useState("");

    /* Desabilitar outro */
    const [outroEspecifidadesOff, setOutroEspecifidadesOff] = useState(true);

    /* Desabilitar Povo Indigena */
    const [povoOff, setPovoOff] = useState(true);

    /* Set CD_ESPECIFIDADES e DS_ESPECIFIDADES */
    const setEspecifidadesSociais = (codigo) => {

        setCd_especifidades_sociais(codigo);

        if (codigo === '1') {
            setDs_especifidades_outro(null);
            setDs_povo(null);
            setOutroEspecifidadesOff(true);
            setPovoOff(true);
            setDs_especifidades_sociais('Família/pessoa em situação de rua');
        }
        else if (codigo === '2') {
            setDs_especifidades_outro(null);
            setDs_povo(null);
            setOutroEspecifidadesOff(true);
            setPovoOff(true);
            setDs_especifidades_sociais('Família quilombola');
        }
        else if (codigo === '3') {
            setDs_especifidades_outro(null);
            setDs_povo(null);
            setOutroEspecifidadesOff(true);
            setPovoOff(true);
            setDs_especifidades_sociais('Família ribeirinha');
        }
        else if (codigo === '4') {
            setDs_especifidades_outro(null);
            setDs_povo(null);
            setOutroEspecifidadesOff(true);
            setPovoOff(true);
            setDs_especifidades_sociais('Família cigana');
        }
        else if (codigo === '5') {
            setDs_especifidades_outro(null);
            setOutroEspecifidadesOff(true);
            setPovoOff(false);
            setDs_especifidades_sociais('Família indígena residente em aldeia/reserva');
        }
        else if (codigo === '6') {
            setDs_especifidades_outro(null);
            setOutroEspecifidadesOff(true);
            setPovoOff(false);
            setDs_especifidades_sociais('Família indígena não residente em aldeia/reserva');
        }

        else if (codigo === '7') {
            setDs_povo(null);
            setOutroEspecifidadesOff(false);
            setPovoOff(true);
        }

    }

    const setEspecifidadesSociaisOutro = (texto) => {

        setDs_especifidades_outro(texto);
        setDs_especifidades_sociais(texto);
    }

    const enviarFamilia = () => {

        setResponsavel(false);
        setValue(2);

    };

    /* --------------------------  Responsavel ----------------------------- */

    // Dados Pessoais
    const [nm_pessoa, setNm_pessoa] = useState("");
    const [nm_apelido, setNm_apelido] = useState("");
    const [nm_mae, setNm_mae] = useState("");
    const [nm_pai, setNm_pai] = useState("");
    const [nm_naturalidade, setNm_naturalidade] = useState("");
    const [ds_sexo, setDs_sexo] = useState("");
    const [dt_nascimento, setDt_nascimento] = useState("");
    const [nr_nis, setNr_nis] = useState("");
    const [nr_ctps, setNr_ctps] = useState("");
    const [nr_serie_ctps, setNr_serie_ctps] = useState("");
    const [nr_cpf, setNr_cpf] = useState("");
    const [nr_rg, setNr_rg] = useState("");
    const [dt_emissao_rg, setDt_emissao_rg] = useState("");
    const [nm_orgao_emissor, setNm_orgao_emissor] = useState("");
    const [uf_rg, setUf_rg] = useState("");
    const [st_deficiente, setSt_deficiente] = useState("");
    const [st_documento, setSt_documento] = useState("");
    const [ds_documentacao_civil, setDs_documentacao_civil] = useState("");
    const [documentacaoOff, setDocumentacaoOff] = useState(true);

    useEffect(() => {
        if (st_documento === 'Sim') {
            setDocumentacaoOff(false);
        } else {
            setDocumentacaoOff(true);
        }

    }, [st_documento])

    // Endereço
    const [nm_bairro, setNm_bairro] = useState("");
    const [nm_logradouro, setNm_logradouro] = useState("");
    const [nr_residencia, setNr_residencia] = useState("");
    const [ds_referencia, setDs_referencia] = useState("");
    const [nr_cep, setNr_cep] = useState("");
    const [ds_localizacao, setDs_localizacao] = useState("");
    const [st_abrigo, setSt_abrigo] = useState("");

    const setEndereco = (nr_cep) => {
        setNr_cep(nr_cep);
        nr_cep = nr_cep.replace(/[^\d]+/g, '');
        if (nr_cep.length >= 8) {
            cep(nr_cep)
                .then(result => {
                    if (result) {
                        setNm_bairro(result.neighborhood);
                        setNm_logradouro(result.street);
                    }
                }).catch(() => {

                })
        }
    }

    /* Telefone */
    const [contato, setContato] = useState([{ tp_telefone: "", nr_ddd: "", nr_telefone: "", botao: true }]);

    /* Add Telefone */
    const addTel = (pos) => {
        let add = [...contato];
        add.push({ tp_telefone: "", nr_ddd: "", nr_telefone: "", botao: true });
        add[pos].botao = false;
        setContato(add);
    }

    /* Remove Telefone */
    const closeTel = (pos) => {
        let tel = [...contato];
        tel.splice(pos, 1);
        setContato(tel);
    }

    /* Set Tipo Telefone */
    const setTp_telefone = (valor, pos) => {
        let add = [...contato];
        add[pos].tp_telefone = valor;
        setContato(add);
    }

    /* Set DDD Telefone */
    const setNr_ddd = (valor, pos) => {
        let add = [...contato];
        add[pos].nr_ddd = valor;
        setContato(add);
    }

    /* Set Numero Telefone */
    const setNr_telefone = (valor, pos) => {
        let add = [...contato];
        add[pos].nr_telefone = valor;
        setContato(add);
    }

    /* Envia tudo */
    const enviarResponsavel = () => {

        if (nm_pessoa === "")
            swal("Nome é obrigatório!", "", "error")

        else if (ds_sexo === "")
            swal("Sexo é obrigatório!", "", "error")

        else if (dt_nascimento === "")
            swal("Data de nascimento é obrigatório!", "", "error")

        else if (nr_cpf === "")
            swal("CPF é obrigatório!", "", "error")

        else if (!isCPF(nr_cpf))
            swal("CPF inválido!", "", "error")

        else if (!isCEP(nr_cep))
            swal("CEP tem 8 dígitos!", "", "error")

        else if (nm_logradouro === "")
            swal("Logradouro é obrigatório!", "", "error")

        else if (nr_residencia === "")
            swal("Número da residência é obrigatório!", "", "error")

        else {

            let continua = false;

            for (let item of contato) {

                if (item.tp_telefone === "" && (item.nr_ddd !== "" || item.nr_telefone !== "")) {
                    continua = false;
                    swal("Preencha todos os campos do contato para enviar!", "", "error");
                    break;
                }

                if (item.nr_ddd === "" && (item.tp_telefone !== "" || item.nr_telefone !== "")) {
                    continua = false;
                    swal("Preencha todos os campos do contato para enviar!", "", "error");
                    break;
                }

                else if (item.nr_telefone === "" && (item.nr_ddd !== "" || item.tp_telefone !== "")) {
                    continua = false;
                    swal("Preencha todos os campos do contato para enviar!", "", "error");
                    break;
                }

                else {
                    continua = true;
                }
            };

            if (continua === true) {

                const formCadastro = {

                    cd_acesso_atendimento,
                    ds_acesso_atendimento,
                    ds_motivacao_atendimento,
                    ds_observacao,
                    dt_atendimento,
                    ds_atendimento,
                    cd_atendimento,
                    nm_tecnico,
                    cd_especifidades_sociais,
                    ds_especifidades_sociais,
                    ds_povo,
                    nm_pessoa,
                    nm_apelido,
                    nm_mae,
                    nm_pai,
                    nm_naturalidade,
                    ds_sexo,
                    dt_nascimento,
                    nr_nis,
                    nr_ctps,
                    nr_serie_ctps,
                    nr_cpf,
                    nr_rg,
                    dt_emissao_rg,
                    nm_orgao_emissor,
                    uf_rg,
                    st_deficiente,
                    st_documento,
                    ds_documentacao_civil,
                    nm_bairro,
                    nm_logradouro,
                    nr_residencia,
                    ds_referencia,
                    nr_cep,
                    ds_localizacao,
                    st_abrigo,
                    contato
                };

                setSending(true);

                LaConFetch(`/api/cadastrarCadastro`, result => {
                    if (result.success) {
                        swal("Cadastro enviado com sucesso!", "", "success")
                        history.push('/admin/prontuario/' + result.familia)
                    } else {
                        swal("Oops", result.message, "error");
                    }
                    setSending(false);
                }, () => { }, formCadastro)

            }

        }

    };

    return (

        <div className={classes.root}>

            <Backdrop style={{ zIndex: 99 }} open={sending}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* -----------------------------  Tabs -------------------------------- */}

            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                    className={classes.Tabs}
                >
                    <TabFixed nome="1 - Atendimento" icon={<ContactPhoneIcon />} aria-label="contactPhoneIcon" {...a11yProps(0, value)} />
                    <TabFixed disabled={familia} nome="2 - Família" icon={<SupervisedUserCircleIcon />} aria-label="supervisedUserCircle" {...a11yProps(1, value)} />
                    <TabFixed disabled={responsavel} nome="3 - Responsável" icon={<PersonIcon />} aria-label="person" {...a11yProps(2, value)} />
                </Tabs>
            </AppBar>

            {/* ----------------------------- Atendimento ----------------------------- */}

            <TabPanel value={value} index={0}>

                <InputLabel style={{ marginTop: 20, marginBottom: 20, fontSize: 20 }}>Detalhes do Atendimento</InputLabel>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="cd_acesso_atendimento"
                    value={cd_acesso_atendimento}
                    label="De que forma a família (ou membro da família) acessou a Unidade para o primeiro atendimento?"
                    onChange={(e) => setAcessoAtendimento(e.target.value)}
                    select>
                    {tipoAcessoAtendimentos.map(item => <MenuItem value={item.id}>{item.title}</MenuItem>)}
                </TextField>

                <TextField
                    disabled={outroAcessoAtendimentoOff}
                    margin="normal"
                    fullWidth
                    value={ds_acesso_atendimento_outro}
                    id="ds_acesso_atendimento_outro"
                    label="Caso outro, descreva a situação"
                    variant="outlined"
                    onChange={(e) => setOutroAcessoAtendimento(e.target.value)}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    id="ds_motivacao_atendimento"
                    label="Quais as razões, demandas ou necessidades que motivaram este primeiro atendimento?"
                    variant="outlined"
                    value={ds_motivacao_atendimento}
                    onChange={(e) => setDs_motivacao_atendimento(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <TextField
                    margin="normal"
                    fullWidth
                    id="ds_observacao"
                    label="Observação"
                    variant="outlined"
                    value={ds_observacao}
                    onChange={(e) => setDs_observacao(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                {/* ---------------------- Registro do Atendimento ---------------------- */}

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Registro do Atendimento</InputLabel>

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_atendimento"
                    label="Data do atendimento*"
                    type="date"
                    value={dt_atendimento}
                    onChange={(e) => setDt_atendimento(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    variant="outlined"
                    style={{ marginTop: 15, marginRight: '2%', width: '42%' }}
                    id="cd_atendimento"
                    value={cd_atendimento}
                    label="Código do atendimento"
                    onChange={(e) => setAtendimento(e.target.value)}
                    select>
                    <MenuItem value="1">1 - Atendimento socioassistencial individualizado</MenuItem>
                    <MenuItem value="2">2 - Atendimento em atividade coletiva de caráter continuado</MenuItem>
                    <MenuItem value="3">3 - Participação em atividade coletiva de caráter não continuado</MenuItem>
                    <MenuItem value="4">4 - Cadastramento / Atualização Cadastral</MenuItem>
                    <MenuItem value="5">5 - Acompanhamento de MSE</MenuItem>
                    <MenuItem value="6">6 - Solicitação / Concessão de Benefício Eventual</MenuItem>
                    <MenuItem value="7">7 - Visita Domiciliar</MenuItem>
                    <MenuItem value="8">8 - Outro</MenuItem>
                </TextField>

                {/* {cd_atendimento == '8'? */}
                <TextField
                    disabled={outroAtendimentoOff}
                    margin="normal"
                    value={ds_atendimento_outro}
                    style={{ width: '38%' }}
                    id="ds_atendimento_outro"
                    label="Caso outro, qual?"
                    variant="outlined"
                    onChange={(e) => setOutroAtendimento(e.target.value)}
                />
                {/* :null} */}

                <TextField
                    margin="normal"
                    fullWidth
                    id="nm_tecnico"
                    label="Nome do Técnico*"
                    variant="outlined"
                    value={nm_tecnico}
                    onChange={(e) => setNm_tecnico(e.target.value)}
                />

                <br></br><br></br>

                <Button onClick={enviarAtendimento} style={{ float: 'right' }} variant="contained" color="primary">
                    Próximo
                </Button>

                <br></br><br></br>

            </TabPanel>

            {/* ------------------------- Especifidades Sociais ------------------------- */}

            <TabPanel value={value} index={1}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Especifidades Sociais</InputLabel>

                <TextField
                    variant="outlined"
                    style={{ marginTop: 15, marginRight: '2%', width: '40%' }}
                    id="cd_especifidades_sociais"
                    value={cd_especifidades_sociais}
                    label="Código da especifidade social"
                    onChange={(e) => setEspecifidadesSociais(e.target.value)}
                    select>
                    <MenuItem value="1">1 - Família/pessoa em situação de rua</MenuItem>
                    <MenuItem value="2">2 - Família quilombola</MenuItem>
                    <MenuItem value="3">3 - Família ribeirinha</MenuItem>
                    <MenuItem value="4">4 - Família cigana</MenuItem>
                    <MenuItem value="5">5 - Família indígena residente em aldeia/reserva</MenuItem>
                    <MenuItem value="6">6 - Família indígena não residente em aldeia/reserva</MenuItem>
                    <MenuItem value="7">7 - Outro</MenuItem>
                </TextField>

                {/* {cd_especifidades_sociais == '7'? */}
                <TextField
                    disabled={outroEspecifidadesOff}
                    margin="normal"
                    style={{ width: '58%' }}
                    value={ds_especifidades_outro}
                    id="ds_especifidades_outro"
                    label="Caso outro, qual?"
                    variant="outlined"
                    onChange={(e) => setEspecifidadesSociaisOutro(e.target.value)}
                />
                {/* :null} */}

                <TextField
                    disabled={povoOff}
                    margin="normal"
                    fullWidth
                    id="ds_povo"
                    label="Povo/Etnia (família indígena)"
                    variant="outlined"
                    value={ds_povo}
                    onChange={(e) => setDs_povo(e.target.value)}
                />

                <br></br><br></br>

                <Button onClick={enviarFamilia} style={{ float: 'right' }} variant="contained" color="primary">
                    Próximo
                </Button>

                <Button onClick={voltarFamilia} style={{ float: 'left' }} variant="contained" color="secondary">
                    Voltar
                </Button>

                <br></br><br></br>

            </TabPanel>

            {/* --------------------------- Responsavel --------------------------- */}

            <TabPanel value={value} index={2}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Dados Pessoais</InputLabel>

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '49%' }}
                    id="nm_pessoa"
                    label="Nome*"
                    variant="outlined"
                    value={nm_pessoa}
                    onChange={(e) => setNm_pessoa(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '49%' }}
                    id="nm_apelido"
                    label="Apelido"
                    variant="outlined"
                    value={nm_apelido}
                    onChange={(e) => setNm_apelido(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '49%' }}
                    id="nm_mae"
                    label="Nome da Mãe"
                    variant="outlined"
                    value={nm_mae}
                    onChange={(e) => setNm_mae(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '49%' }}
                    id="nm_pai"
                    label="Nome do Pai"
                    variant="outlined"
                    value={nm_pai}
                    onChange={(e) => setNm_pai(e.target.value)}
                />

                <FormControl style={{ marginTop: 15, marginRight: '2%', width: '20%' }} component="fieldset">
                    <FormLabel component="legend">Sexo*</FormLabel>
                    <RadioGroup row aria-label="sexo" name="sexo" value={ds_sexo} onChange={(e) => setDs_sexo(e.target.value)}>
                        <FormControlLabel value="Feminino" control={<Radio color="primary" />} label="Feminino" />
                        <FormControlLabel value="Masculino" control={<Radio color="primary" />} label="Masculino" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_nascimento"
                    label="Data de Nascimento*"
                    type="date"
                    value={dt_nascimento}
                    onChange={(e) => setDt_nascimento(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '26%' }}
                    id="nm_naturalidade"
                    label="Naturalidade"
                    variant="outlined"
                    value={nm_naturalidade}
                    onChange={(e) => setNm_naturalidade(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '32%' }}
                    id="nr_nis"
                    label="Nº NIS"
                    variant="outlined"
                    value={nr_nis}
                    onChange={(e) => setNr_nis(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '32%' }}
                    id="nr_ctps"
                    label="CTPS"
                    variant="outlined"
                    value={nr_ctps}
                    onChange={(e) => setNr_ctps(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '32%' }}
                    id="nr_serie_ctps"
                    label="Série"
                    variant="outlined"
                    value={nr_serie_ctps}
                    onChange={(e) => setNr_serie_ctps(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '32%' }}
                    id="nr_cpf"
                    label="CPF*"
                    variant="outlined"
                    InputProps={{ inputComponent: CPFMask }}
                    value={nr_cpf}
                    error={nr_cpf && !isCPF(nr_cpf)}
                    helperText={nr_cpf && !isCPF(nr_cpf) ? "CPF inválido" : ""}
                    onChange={(e) => setNr_cpf(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '32%' }}
                    id="nr_rg"
                    label="RG*"
                    variant="outlined"
                    value={nr_rg}
                    onChange={(e) => setNr_rg(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    style={{ marginTop: 15, marginRight: '2%', width: '8%' }}
                    id="uf_rg"
                    value={uf_rg}
                    label="RG UF"
                    onChange={(e) => setUf_rg(e.target.value)}
                    select>
                    <MenuItem value="1">AC</MenuItem>
                    <MenuItem value="2">AL</MenuItem>
                    <MenuItem value="3">AP</MenuItem>
                    <MenuItem value="4">AM</MenuItem>
                    <MenuItem value="5">BA</MenuItem>
                    <MenuItem value="6">CE</MenuItem>
                    <MenuItem value="7">DF</MenuItem>
                    <MenuItem value="8">ES</MenuItem>
                    <MenuItem value="9">GO</MenuItem>
                    <MenuItem value="10">MA</MenuItem>
                    <MenuItem value="11">MT</MenuItem>
                    <MenuItem value="12">MS</MenuItem>
                    <MenuItem value="13">MG</MenuItem>
                    <MenuItem value="14">PA</MenuItem>
                    <MenuItem value="15">PB</MenuItem>
                    <MenuItem value="16">PR</MenuItem>
                    <MenuItem value="17">PE</MenuItem>
                    <MenuItem value="18">PI</MenuItem>
                    <MenuItem value="19">RJ</MenuItem>
                    <MenuItem value="20">RN</MenuItem>
                    <MenuItem value="21">RS</MenuItem>
                    <MenuItem value="22">RO</MenuItem>
                    <MenuItem value="23">RR</MenuItem>
                    <MenuItem value="24">SC</MenuItem>
                    <MenuItem value="25">SP</MenuItem>
                    <MenuItem value="26">SE</MenuItem>
                    <MenuItem value="27">TO</MenuItem>
                </TextField>

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="nm_orgao_emissor"
                    label="Órgao de Emissão"
                    variant="outlined"
                    value={nm_orgao_emissor}
                    onChange={(e) => setNm_orgao_emissor(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ width: '16%' }}
                    id="dt_emissao_rg"
                    label="Data de Emissão"
                    type="date"
                    value={dt_emissao_rg}
                    onChange={(e) => setDt_emissao_rg(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Dados Gerais</InputLabel>

                <FormControl style={{ marginTop: 15, marginRight: '2%', width: '16%' }} component="fieldset">
                    <FormLabel component="legend">Possui alguma Deficiência?</FormLabel>
                    <RadioGroup row value={st_deficiente} aria-label="st_deficiente" name="st_deficiente" onChange={(e) => setSt_deficiente(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <FormControl style={{ marginTop: 15, marginRight: '2%', width: '24%' }} component="fieldset">
                    <FormLabel component="legend">Precisa providenciar algum documento?</FormLabel>
                    <RadioGroup row value={st_documento} aria-label="st_documento" name="st_documento" onChange={(e) => setSt_documento(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    disabled={documentacaoOff}
                    margin="normal"
                    style={{ width: '56%' }}
                    id="ds_documentacao_civil"
                    label="Caso Sim, quais?"
                    variant="outlined"
                    value={ds_documentacao_civil}
                    onChange={(e) => setDs_documentacao_civil(e.target.value)}
                />

                {/* --------------------------- Endereço --------------------------- */}

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="nr_cep"
                    label="CEP"
                    InputProps={{ inputComponent: CEPMask }}
                    variant="outlined"
                    value={nr_cep}
                    onChange={(e) => setEndereco(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '72%' }}
                    id="nm_logradouro"
                    label="Logradouro*"
                    variant="outlined"
                    value={nm_logradouro}
                    onChange={(e) => setNm_logradouro(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '8%' }}
                    id="nr_residencia"
                    label="Número*"
                    variant="outlined"
                    value={nr_residencia}
                    onChange={(e) => setNr_residencia(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '32%' }}
                    id="nm_bairro"
                    label="Bairro"
                    variant="outlined"
                    value={nm_bairro}
                    onChange={(e) => setNm_bairro(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '66%' }}
                    id="ds_referencia"
                    label="Ponto de Referência"
                    variant="outlined"
                    value={ds_referencia}
                    onChange={(e) => setDs_referencia(e.target.value)}
                />

                <FormControl style={{ marginRight: '2%', marginTop: 15, width: '18%' }} component="fieldset">
                    <FormLabel component="legend">Vive em situação de abrigo?</FormLabel>
                    <RadioGroup row aria-label="st_abrigo" name="st_abrigo" value={st_abrigo} onChange={(e) => setSt_abrigo(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    margin="normal"
                    style={{ width: '80%' }}
                    id="ds_localizacao"
                    label="Descrição do local"
                    variant="outlined"
                    value={ds_localizacao}
                    onChange={(e) => setDs_localizacao(e.target.value)}
                />

                {/* --------------------------- Telefone --------------------------- */}

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Contato</InputLabel>

                {contato.map((item, pos) =>
                    <div>
                        <TextField
                            variant="outlined"
                            style={{ marginTop: 15, marginRight: '2%', width: '14%' }}
                            id="tp_telefone"
                            value={item.tp_telefone}
                            label="Tipo de Telefone"
                            onChange={(e) => setTp_telefone(e.target.value, pos)}
                            select>
                            <MenuItem value="CELULAR">CELULAR</MenuItem>
                            <MenuItem value="RESIDENCIAL">RESIDENCIAL</MenuItem>
                            <MenuItem value="COMERCIAL">COMERCIAL</MenuItem>
                            <MenuItem value="PROFISSIONAL">PROFISSIONAL</MenuItem>
                        </TextField>

                        <TextField
                            margin="normal"
                            style={{ marginRight: '2%', width: '8%' }}
                            id="nr_ddd"
                            label="DDD"
                            inputProps={{
                                maxLength: 2
                            }}
                            variant="outlined"
                            value={item.nr_ddd}
                            onChange={(e) => setNr_ddd(e.target.value, pos)}
                        />

                        <TextField
                            margin="normal"
                            style={{ width: '18%', marginRight: '2%' }}
                            id="nr_telefone"
                            label="Número"
                            inputProps={{
                                maxLength: 9
                            }}
                            variant="outlined"
                            value={item.nr_telefone}
                            onChange={(e) => setNr_telefone(e.target.value, pos)}
                        />

                        {item.botao === true ?
                            <Fab style={{ marginTop: 21, marginRight: '40%' }} onClick={(e) => addTel(pos)} size="small" color="primary" aria-label="addFaixa">
                                <AddIcon />
                            </Fab>
                            : null}

                        {item.botao === false ?
                            <Fab style={{ marginTop: 21, marginRight: '40%' }} onClick={(e) => closeTel(pos)} size="small" color="secundary" aria-label="closeFaixa">
                                <CloseIcon />
                            </Fab>
                            : null}

                    </div>)
                }

                <br></br><br></br>

                <Button onClick={enviarResponsavel} style={{ float: 'right' }} variant="contained" color="primary">
                    Concluir Cadastro
                </Button>

                <Button onClick={voltarResponsavel} style={{ float: 'left' }} variant="contained" color="secondary">
                    Voltar
                </Button>

                <br></br><br></br>

            </TabPanel>

        </div>
    );
}
