import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));

export default function Gestacao({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // Gestação
    const [id, setId] = useState("");
    const [qt_meses, setQt_meses] = useState("");
    const [dt_anotacao, setDt_anotacao] = useState("");
    const [st_pre_natal, setSt_pre_natal] = useState("");
    const [st_zika, setSt_zika] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    /* Cadastrar Gestação*/
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (qt_meses === "")
            swal("Quantidade de meses é obrigatório!", "", "error")

        else if (dt_anotacao === "")
            swal("Data da anotação é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                qt_meses,
                dt_anotacao,
                st_pre_natal,
                st_zika,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarGestacao`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e Gestação*/
    const [rows, setRows] = useState([]);

    const load = () => {
        setLoading(true);
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultGestacao/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))
    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosGestacao */
    const setDadosGestacao = (dados) => {

        /* Dados */
        setId(dados.id);
        setQt_meses(dados.qt_meses);
        setDt_anotacao(dados.dt_anotacao);
        setSt_pre_natal(dados.st_pre_natal);
        setSt_zika(dados.st_zika);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setQt_meses("");
        setDt_anotacao("");
        setSt_pre_natal("");
        setSt_zika("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosGestacao(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar Gestação*/
    const editar = () => {

        if (qt_meses === "")
            swal("Quantidade de meses é obrigatório!", "", "error")

        else {

            const formDados = {
                qt_meses,
                dt_anotacao,
                st_pre_natal,
                st_zika,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/editarGestacao/${id}`,
                result => {
                    load();
                    setSending(false);
                    swal("Editado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Remover Gestação */
    const remover = (dados) => {
        const id = dados.id;
        setSending(true);
        LaConFetch(`/api/removerGestacao/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})
    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>Gestação</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar Gestação
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Quantidade Meses', field: 'qt_meses', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data da Anotação', field: 'dt_anotacaoTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            components={{
                Pagination: PatchedPagination
            }}
            options={{
                actionsColumnIndex: -1
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Gestação"
        />

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <div className={classes.paper}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Gestação</InputLabel>

                {(editing === false) ? <TextField
                    label="Nome da pessoa*"
                    variant="outlined" fullWidth margin="normal" select
                    value={pessoa_id} onChange={(e) => setPessoa_id(e.target.value)}
                >
                    {dadosPessoa.map(item =>
                        <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                    )}
                </TextField> : <TextField
                    label="Nome da pessoa"
                    disabled={true}
                    margin="normal" variant="outlined" fullWidth
                    value={nm_pessoa}
                />}

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '20%' }}
                    id="qt_meses"
                    label="Quantidade de meses gestando*"
                    variant="outlined"
                    value={qt_meses}
                    onChange={(e) => setQt_meses(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_anotacao"
                    label="Data da Anotação*"
                    type="date"
                    value={dt_anotacao}
                    onChange={(e) => setDt_anotacao(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <FormControl style={{ marginTop: 15, marginRight: '2%', width: '14%' }} component="fieldset">
                    <FormLabel component="legend">Já inicou Pré-Natal?</FormLabel>
                    <RadioGroup row value={st_pre_natal} aria-label="st_pre_natal" name="st_pre_natal" onChange={(e) => setSt_pre_natal(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <FormControl style={{ marginTop: 15, width: '23%' }} component="fieldset">
                    <FormLabel component="legend">Tem ou teve Zika durante a Gestação?</FormLabel>
                    <RadioGroup row value={st_zika} aria-label="st_zika" name="st_zika" onChange={(e) => setSt_zika(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    margin="normal"
                    style={{ width: '100%' }}
                    id="ds_observacao"
                    label="Observação"
                    variant="outlined"
                    value={ds_observacao}
                    onChange={(e) => setDs_observacao(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <br></br><br></br>

                <Button onClick={handleClose} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={(editing === false) ? enviar : editar} style={{ float: 'right' }} variant="contained" color="primary">
                    Enviar
                </Button>

                <br></br><br></br>

            </div>

        </Modal>
    </div>
}