import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop, Modal, Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function Violencia({ familia_id, filter }) {
    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");
    // Violencia
    const [id, setId] = useState("");
    const [cd_violencia, setCd_violencia] = useState("");
    const [st_violencia, setSt_violencia] = useState("");
    const [st_persiste, setSt_persiste] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [st_violencia_outro, setSt_violencia_outro] = useState("");
    const [outroViolenciaOff, setOutroViolenciaOff] = useState(true);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    /* Set Violencia */
    const setViolencia = (codigo) => {

        setCd_violencia(codigo);

        if (codigo === '1') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Trabalho Infantil');
        }
        else if (codigo === '2') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Exploração Sexual');
        }
        else if (codigo === '3') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Abuso/Violência Sexual');
        }
        else if (codigo === '4') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Violência Física');
        }
        else if (codigo === '5') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Negligência contra idoso');
        }
        else if (codigo === '6') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Negligência contra criança');
        }
        else if (codigo === '7') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Negligência contra PCD');
        }
        else if (codigo === '8') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Trajetória de rua');
        }
        else if (codigo === '9') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Tráfico de pessoas');
        }
        else if (codigo === '10') {
            setSt_violencia_outro(null);
            setOutroViolenciaOff(true);
            setSt_violencia('Violência Patrimonial contra Idoso ou PCD');
        }
        else if (codigo === '11') {
            setOutroViolenciaOff(false);
        }

    }

    const setViolenciaOutro = (texto) => {

        setSt_violencia_outro(texto);
        setSt_violencia(texto);
    }

    const [sending, setSending] = useState(false);

    /* Cadastrar Violencia */
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (cd_violencia === "")
            swal("Situação da violência é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                cd_violencia,
                st_violencia,
                st_persiste,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarViolencia`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e Violencia */
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultViolencia/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosViolencia */
    const setDadosViolencia = (dados) => {

        /* Dados */
        setId(dados.id);
        setViolencia(dados.cd_violencia);
        if (dados.cd_violencia === '11') {
            setViolenciaOutro(dados.st_violencia);
        }
        setSt_persiste(dados.st_persiste);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setCd_violencia("");
        setSt_violencia("")
        setSt_persiste("");
        setDs_observacao("");
        setSt_violencia_outro("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosViolencia(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar Violencia */
    const editar = () => {

        const formDados = {
            cd_violencia,
            st_violencia,
            st_persiste,
            ds_observacao
        };

        setSending(true);

        LaConFetch(`/api/editarViolencia/${id}`,
            result => {
                load();
                setSending(false);
                swal("Enviado com sucesso!", "", "success")
                setOpen(false)
            }, () => { }, formDados)

    };

    /* Remover Violencia */
    const remover = (dados) => {
        const id = dados.id;
        setSending(true);
        LaConFetch(`/api/removerViolencia/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>QUADRO 1 - Histórico de situações de violência e violações de direitos vivenciadas pela família</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar Violência
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Violência', field: 'cd_violencia', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Violência', field: 'st_violencia', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            options={{
                actionsColumnIndex: -1
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_materialTableLocalization} icons={_materialTableIcons}
            title="Violencia"
        />

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <div className={classes.paper}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Violência</InputLabel>

                {(editing === false) ? (
                    <TextField
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 15 }}
                        id="pessoa_id"
                        value={pessoa_id}
                        label="Nome da pessoa*"
                        onChange={(e) => setPessoa_id(e.target.value)}
                        select>
                        {dadosPessoa.map(item =>
                            <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                        )}
                    </TextField>
                ) : (
                    <TextField
                        disabled={true}
                        margin="normal"
                        variant="outlined"
                        style={{ marginTop: 15, width: '100%' }}
                        id="nm_pessoa"
                        value={nm_pessoa}
                        label="Nome da pessoa"
                    />)}

                <TextField
                    variant="outlined"
                    style={{ marginTop: 15, marginRight: '2%', width: '33%' }}
                    id="cd_violencia"
                    value={cd_violencia}
                    label="Qual a situação de Violência?*"
                    onChange={(e) => setViolencia(e.target.value)}
                    select>
                    <MenuItem value="1">1 - Trabalho Infantil</MenuItem>
                    <MenuItem value="2">2 - Exploração Sexual</MenuItem>
                    <MenuItem value="3">3 - Abuso/Violência Sexual</MenuItem>
                    <MenuItem value="4">4 - Violência Física</MenuItem>
                    <MenuItem value="5">5 - Negligência contra idoso</MenuItem>
                    <MenuItem value="6">6 - Negligência contra criança</MenuItem>
                    <MenuItem value="7">7 - Negligência contra PCD</MenuItem>
                    <MenuItem value="8">8 - Trajetória de rua</MenuItem>
                    <MenuItem value="9">9 - Tráfico de pessoas</MenuItem>
                    <MenuItem value="10">10 - Violência Patrimonial contra Idoso ou PCD</MenuItem>
                    <MenuItem value="11">11 - Outro</MenuItem>
                </TextField>

                {/* {st_violencia == 11? */}
                <TextField
                    disabled={outroViolenciaOff}
                    margin="normal"
                    style={{ width: '65%' }}
                    value={st_violencia_outro}
                    id="st_violencia_outro"
                    label="Caso outro, descreva a situação"
                    variant="outlined"
                    onChange={(e) => setViolenciaOutro(e.target.value)}
                />
                {/* :null} */}

                <FormControl style={{ marginTop: 15, width: '20%' }} component="fieldset">
                    <FormLabel component="legend">A situação ainda persiste?</FormLabel>
                    <RadioGroup row value={st_persiste} aria-label="st_persiste" name="st_persiste" onChange={(e) => setSt_persiste(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    margin="normal"
                    style={{ width: '100%' }}
                    id="ds_observacao"
                    label="Observação"
                    variant="outlined"
                    value={ds_observacao}
                    onChange={(e) => setDs_observacao(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <br></br><br></br>

                <Button onClick={handleClose} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={(editing === false) ? enviar : editar} style={{ float: 'right' }} variant="contained" color="primary">
                    Enviar
                </Button>

                <br></br><br></br>

            </div>

        </Modal>

    </div>
}